






















import { defineComponent, useStore } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import PopupFade from './PopupFade.vue'

export default defineComponent({
  name: 'SurveyPopup',
  components: { PopupFade },
  setup() {
    const { getters } = useStore()

    const isMobileMq = computed(() => getters['mediaQuery/isMobileMq'])

    const isShown = computed(() => getters['settings/getShowSurveyPopup'])

    const surveyState = computed(() => getters['settings/survey'])

    return {
      surveyState,
      isShown,
      isMobileMq,
    }
  },
  methods: {
    handleClose() {
      this.$store.commit('settings/TOGGLE_SURVEY', false)
    },
  },
})
